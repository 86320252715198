<template>
	<layout-vertical>

		<router-view />

		<template #navbar="{ toggleVerticalMenuActive }">
			<navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
		</template>

		<span slot="breadcrumb"/>

		<!-- <app-customizer
		v-if="showCustomizer"
		slot="customizer"
		/> -->

		<app-footer slot="footer" />
	</layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppFooter from '@/layouts/components/AppFooter'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
	components: {
		// AppCustomizer,
		LayoutVertical,
		Navbar,
		AppFooter
	},
	data() {
		return {
			// showCustomizer: $themeConfig.layout.customizer,
		}
	},
}
</script>
